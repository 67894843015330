

























































































































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { getList, getListCustomFields } from '@/api/consoleApi/recipients'
import axios from 'axios'
import { fieldsWithDifferentType, missingRequiredFields, notExistingFields } from '@/utils/leadplus'
import { getPreviewImage } from '@/api/leadplus/forms'
import VsImage from '@/modules/landingpages/components/VsImage/Index.vue'

@Component({
    name: 'VsFormCard',
    components: {
        VsListingCard,
        VsImage,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) form!: any

    list: any = null
    customFields: any[] = []
    missingList = false
    $refs: any
    previewImage: any = null

    get defaultImage () {
        return require('@/assets/img/leadplus/form-placeholder.png')
    }

    beforeMount () {
        this.getList()
        this.getCustomFields()
        this.getPreviewImage()
    }

    get badgeStatusAspect () {
        return this.form.isActive ? 'success' : 'default'
    }

    get formType () {
        switch (this.form?.theme?.type) {
            case 'popover':
                return 'Pop up'
            case 'embedded':
                return 'Embedded form'
            case 'topbar':
                return 'Top bar'
            case 'scrollbox':
                return 'Scroll box'
            default:
                return this.form?.theme?.type
        }
    }

    async getList () {
        if (!this.form.foreign_recipient_id) {
            this.missingList = true
            return
        }
        try {
            const resp = await getList(this.form.foreign_recipient_id)
            this.list = resp.data.data
            delete this.list.integration
        } catch (e) {
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 404) {
                    this.missingList = true
                }
            }
            console.log(e)
            this.list = null
        }
    }

    private async getCustomFields () {
        const cf: any[] = []
        await this.loopCall(1, cf, getListCustomFields)
        this.customFields = cf
    }

    async loopCall (page: number, values: any[], caller: any) {
        const resp = await caller(this.form.foreign_recipient_id, {
            page,
            limit: 100,
        })
        values.push(...resp.data.data)
        if (resp?.data?.meta?.pagination?.current_page < resp?.data?.meta?.pagination?.total_pages) {
            await this.loopCall(resp.data.meta.pagination.current_page + 1, values, caller)
        }
    }

    get notExistingFields () {
        if (!this.form || !this.form.pages[0] || !this.customFields || this.customFields.length === 0) return []
        return notExistingFields(this.form.pages[0].fields, this.customFields)
    }

    get fieldsWithDifferentType () {
        if (!this.form || !this.form.pages[0] || !this.customFields || this.customFields.length === 0) return []
        return fieldsWithDifferentType(this.form.pages[0].fields, this.customFields)
    }

    get missingRequiredFields () {
        if (!this.form || !this.customFields || this.customFields.length === 0) return []

        return missingRequiredFields(this.form.pages, this.customFields)
    }

    async getPreviewImage () {
        try {
            const res = await getPreviewImage(this.form._id, {
                t: Date.now(),
            })
            this.previewImage = URL.createObjectURL(res.data)
        } catch (e) {
            this.previewImage = null
        }
    }

    loaded () {
        if (this.previewImage) URL.revokeObjectURL(this.previewImage)
    }
}
