






































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsFilterContainer from '@/components/VsFilterContainer/Index.vue'
import VsFormCard from '@/modules/leadplus/components/VsFormCard/Index.vue'
import CreateFormModal from '@/modules/leadplus/components/CreateFormModal/Index.vue'
import IntegrationFormModal from '@/modules/leadplus/components/IntegrationFormModal/Index.vue'
import VsFogFormPreviewModal from '@/modules/leadplus/components/VsFogFormPreviewModal/Index.vue'
import DuplicateFormModal from '@/modules/leadplus/components/DuplicateFormModal/Index.vue'
import FormNameModal from '@/modules/leadplus/components/FormNameModal/Index.vue'
import { deleteFormById, getForms, updateFormById, updateFormStatusById } from '@/api/leadplus/forms'
import { getLists } from '@/api/consoleApi/recipients'
import { CustomField } from '@/utils/customFields'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { LeadplusForm } from '@/api/leadplus/types'
import { UserModule } from '@/store/modules/user'
import VsEmptyState from '@/components/VsEmptyState/Index.vue'
@Component({
    name: 'LeadplusForms',
    components: {
        VsContainer,
        VsSectionHeader,
        VsFilterContainer,
        VsConfirm,
        VsDropdownButton,
        CreateFormModal,
        IntegrationFormModal,
        VsFormCard,
        VsFogFormPreviewModal,
        DuplicateFormModal,
        FormNameModal,
        VsEmptyState,
    },
})
export default class extends Vue {
    emptyState = false
    loadingEmpty = false
    forms: any[] = []
    total = 0
    userTotalForms = 0
    loading = false
    list: any = null
    lists = []

    $refs!: any
    filters = {
        type: '',
        list: '',
        search: '',
    }

    pagination: any = {
        page: 1,
        itemsPerPage: 5,
        orderBy: null,
    }

    get user () {
        return UserModule.user
    }

    get hasShop () {
        return this.user.configuration.rules.shop
    }

    get formLimit () {
        return this.user.configuration.rules.leadplusForms
    }

    get showFormBanner () {
        if (this.formLimit > -1 && this.userTotalForms >= this.formLimit - 1) return true
        return false
    }

    get campaignMessageOrderBy () {
        return [
            {
                label: this.$t('campaigns.index.orderBy.createdAtDesc'),
                value: '-_id',
            },
            {
                label: this.$t('campaigns.index.orderBy.createdAtAsc'),
                value: '_id',
            },
        ]
    }

    get countFilters () {
        let count = 0
        if (this.filters.type) count++
        if (this.filters.list) count++
        if (this.filters.search) count++
        return count
    }

    beforeMount () {
        this.getForms()
    }

    @Watch('filters', { deep: true, immediate: false })
    @Watch('pagination', { deep: true, immediate: false })
    async getForms () {
        this.loading = true
        this.checkHasForm()
        try {
            const resp = await getForms(this.generateQuery())
            this.forms = resp.data.docs
            this.total = resp.data.count
        } catch (e) {
            console.log(e)
            this.forms = []
            this.total = 0
        }
        this.loading = false
    }

    async checkHasForm () {
        this.loadingEmpty = true
        try {
            const resp = await getForms({ limit: 1 })
            this.userTotalForms = resp.data.count
            if (resp.data.count === 0) {
                this.emptyState = true
            }
        } catch (e) {
            console.log(e)
        }
        this.loadingEmpty = false
    }

    generateQuery () {
        const query: any = {
            limit: this.pagination.itemsPerPage,
            skip: (this.pagination.page - 1) * this.pagination.itemsPerPage,
            sort: this.pagination.orderBy || '-_id',
            // populate: 'formgroup,formlist',
        }

        const filter: any = {
            $and: [],
        }

        if (this.filters.search) {
            const checkForHexRegExp = /^[a-f\d]{24}$/i
            const $or: any[] = [
                { name: { $regex: this.filters.search, $options: 'i' } },
            ]
            if (checkForHexRegExp.test(this.filters.search)) {
                $or.push({ _id: this.filters.search })
            }
            filter.$and.push({
                $or,
            })
        }

        if (this.filters.type) {
            filter.$and.push({
                'theme.type': this.filters.type,
            })
        }

        if (this.filters.list) {
            filter.$and.push({
                foreign_recipient_id: this.filters.list + '',
            })
        }

        if (filter.$and.length > 0) {
            query.filter = JSON.stringify(filter)
        }
        return query
    }

    async setActive (isActive: boolean, formId: string) {
        try {
            await updateFormStatusById(formId, {
                isActive,
            })
            this.$root.$vsToast({
                heading: `Form ${isActive ? 'attivato' : 'disattivato'} con successo`,
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getForms()
        } catch (e) {
            this.$root.$vsToast({
                heading: `Errore durante ${isActive ? 'l\'attivazione' : 'la disattivazione'} del form`,
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    async deleteForm (form: any) {
        try {
            await this.$refs.confirmFormDelete.openConfirm()
        } catch (e) {
            console.log(e)
            return
        }

        try {
            await deleteFormById(form._id)
            this.$root.$vsToast({
                heading: 'Form cancellato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione del form',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }

        this.getForms()
    }

    openCode (form: any) {
        this.$refs.integrationFormModal.openModal(form)
    }

    selectList (item: string) {
        const list: any = this.lists.find((el: any) => el.id === item)
        if (!list) {
            this.filters.list = ''
            this.list = null
        } else {
            this.filters.list = list.id
            this.list = list
        }
    }

    formatBadgeLabel (item: any) {
        return this.list.name || item
    }

    async getLists (search: string) {
        try {
            const response = await getLists(this.buildParams(search))
            this.lists = response.data.data
        } catch (e) {

        }
    }

    private buildParams (search: string) {
        const params: any = {
            orderBy: 'created_at',
            sortedBy: 'desc',
            page: 1,
            limit: 100,
            searchJoin: 'and',
            search: this.buildSearch(search),
            searchFields: this.buildSearchFields(search),
        }

        return params
    }

    private buildSearch (search: string) {
        const arr = [
            search.trim() !== '' ? `name:${search}` : '',
        ].filter((el) => el !== '')

        return arr.join(';')
    }

    private buildSearchFields (search: string) {
        const searchFields = [
            search.trim() !== '' ? 'name:like' : '',
        ].filter((el) => el !== '')

        return searchFields.join(';')
    }

    async cleanFields (form: any, customFields: CustomField[]) {
        try {
            await updateFormById(form._id, {
                pages: form.pages.map((page: any) => {
                    return {
                        ...page,
                        fields: page.fields.filter((field: any) => {
                            return customFields.find(el => field.fieldId === el.id)
                        }),
                    }
                }),
            })
            this.$root.$vsToast({
                heading: 'Campi cancellati con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.getForms()
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la cancellazione dei campi',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            console.log(e)
        }
    }

    formCreated (form: LeadplusForm) {
        this.$router.push({
            name: 'editForm',
            params: {
                formId: form._id,
            },
        })
    }
}
